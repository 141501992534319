@import "../../styles/_mixin.scss";

#resume {
	display: flex;
	align-items: center;

	.block {
		width: 90%;
		height: 90%;
		margin: 0 auto;
		border-radius: 2px;
		position: relative;
		padding: 20px 20px;
		background-color: rgba(45, 58, 75, 0.5);
		overflow: auto;
	}

	.wrapper {
		display: flex;

		@media (max-width: $bp-mobile) {
			flex-direction: column;
		}
	}

	.column {
		margin: 0 15px;

		@media (max-width: $bp-mobile) {
			margin-bottom: 15px;
		}
	}

	h3 {
		font-size: rem(20);
		margin-bottom: 10px;
	}

	h4 {
		font-size: rem(16);
		margin-bottom: 5px;
	}

	ul {
		font-size: rem(13);

		&.education,
		&.experience {
			li {
				padding-bottom: 20px;
				padding-left: 20px;
				border-left: 2px solid rgb(0, 122, 255);
				position: relative;

				&:before {
					content: "";
					position: absolute;
					width: 16px;
					height: 16px;
					border-radius: 50px;
					left: -10px;
					top: 0;
					background-color: #fff;
					border: 2px solid rgb(0, 122, 255);
				}
			}
		}

		li {
			padding-bottom: 20px;
		}
	}

	.company {
		font-size: rem(14);
	}

	.date {
		margin-bottom: 5px;
	}

	.desc {
		font-style: italic;
	}

	.education {
		margin-bottom: 30px;
	}
}
