$browser-context: 16; // Default

@function rem(
	$pixels,
	$context: $browser-context
) {
	@return #{$pixels/$context}rem;
}

$bp-mobile: 640px;
$bp-tablet: 1024px;
