@import "../../styles/_mixin.scss";

#about {
	display: flex;
	align-items: center;

	.block {
		width: 70%;
		height: 70%;
		margin: 0 auto;
		border-radius: 2px;
		position: relative;
		background-color: rgba(45, 58, 75, 0.5);

		@media (max-width: $bp-mobile) {
			width: 80%;
		}
	}
	img {
		width: 420px;
		max-width: 100%;
		border-radius: 5px;
		margin: 30px 50px;
		display: block;

		@media (max-width: $bp-mobile) {
			margin: 30px 15px 0 15px;
			max-width: calc(100% - 30px);
		}
	}
	.text {
		background-color: #fff;
		border-radius: 2px;
		width: 300px;
		position: absolute;
		top: 35%;
		left: calc(420px + 50px - 100px);
		padding: 20px;
		font-size: rem(15);
		color: #333;

		@media (max-width: $bp-tablet) {
			left: 50%;
		}

		@media (max-width: $bp-mobile) {
			position: static;
			width: calc(100% - 30px);
			margin-left: 15px;
			max-height: 40%;
			overflow: auto;
		}
	}
}
