@import "./styles/_mixin.scss";

#main {
	height: 100vh;
	overflow: hidden;

	@media (max-width: $bp-tablet) {
		height: 100vh; /* Fallback for browsers that do not support Custom Properties */
		height: calc(var(--vh, 1vh) * 100);
	}

	.page {
		height: 100%;
		position: relative;
		z-index: 1;
		touch-action: none;
	}

	#content {
		position: relative;
		height: 100%;
		color: #fff;

		@media (max-width: $bp-tablet) {
			height: calc(100% - 95px);
		}
	}

	#background {
		display: block;
		height: 100%;
		z-index: 0;
		overflow: hidden;

		&:after {
			content: "";
			background: rgba(5, 13, 24, 0.3);
			position: absolute;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;
		}

		img {
			object-fit: cover;
			max-width: 100%;
			z-index: 0;
			position: relative;
		}

		@media (max-width: $bp-tablet) {
			height: calc(100% - 95px);

			img {
				object-fit: none;
				max-width: none;
				height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

.no-landscape {
	height: 100vh;
	justify-content: center;
	align-items: center;
	display: none;

	@media (max-width: $bp-tablet) and (orientation: landscape) {
		display: flex;
	}
}
