@import "../../styles/_mixin.scss";

#showcases {
	.block {
		display: flex;
		height: 100%;
		align-items: center;
		width: 90%;
		margin: 0 auto;
		text-align: center;
	}

	.slider {
		position: relative;
		width: 100%;
		max-height: 100%;
		padding-bottom: 25px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		background-color: transparent;
		border: none;
		outline: none;
	}

	.swiper-button-prev {
		left: 0;
	}

	.swiper-button-next {
		right: 0;
	}

	.swiper-pagination {
		bottom: 0;
		width: 100%;

		.swiper-pagination-bullet {
			margin: 0 3px;
		}
	}

	.swiper-container {
		width: calc(100% - 27px - 27px - 20px);
		margin: 0 auto;

		.swiper-slide {
			width: 180px;

			img {
				width: 100%;
				height: 200px;
				object-fit: contain;
				border-radius: 5px;
				display: block;
				padding: 5px;
				background-color: rgba(45, 58, 75, 0.5);
			}

			.text {
				background-color: #fff;
				padding: 25px 30px;

				.name {
					color: #121212;
					font-size: rem(18);
					margin-bottom: 15px;
					padding-bottom: 5px;
					border-bottom: 2px solid #eee;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.desc {
					color: #333;
					display: block;
					margin-bottom: 15px;
					font-size: rem(12);
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					min-height: 42px;
				}

				a {
					background-color: #fa5252;
					color: #fff;
					display: block;
					width: 80%;
					margin: 0 auto;
					padding: 12px 18px;
					font-size: rem(11);
					text-decoration: none;
					text-transform: uppercase;
				}
			}
		}
	}
}
