@import "../../styles/_mixin.scss";

#sidebar {
	font-family: "Quicksand", sans-serif;
	padding: 45px;
	width: 260px;
	height: 100vh;
	border-right: 1px solid #d1cbca;
	background-color: #fff;
	z-index: 10;

	@media (max-width: $bp-tablet) {
		padding: 30px;
	}

	@media (max-width: $bp-mobile) {
		padding: 30px 10px;
	}

	.logo {
		display: inline-block;
		background-color: #000;
		color: #fff;
		padding: 12px 6px 12px 16px;
		text-align: center;
		margin-bottom: 22px;
		outline: none;
		border: none;
		cursor: pointer;

		span {
			display: block;
			font-size: rem(22);
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: 10px;
		}

		@media (max-width: $bp-tablet) {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin-bottom: 0;

			span {
				font-size: rem(16);
			}
		}

		@media (max-width: $bp-mobile) {
			display: none;
		}
	}

	.menu {
		margin-bottom: 60px;

		.menu-link {
			border: none;
			background-color: transparent;
			font-size: rem(12);
			text-transform: uppercase;
			padding: 10px 0;
			cursor: pointer;
			color: rgba(0, 0, 0, 0.4);
			transition: color 0.3s;
			outline: none;
			display: flex;
			align-items: center;
			line-height: 1;
			text-decoration: none;

			i {
				width: 20px;
				margin-right: 8px;
				text-align: center;
				font-size: rem(15);
			}

			&:hover,
			&.active {
				font-weight: 600;
				color: #000;
			}
		}

		@media (max-width: $bp-tablet) {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;

			li {
				margin-left: 30px;

				&:first-child {
					margin-left: 0;
				}

				.menu-link {
					font-size: rem(16);

					i {
						font-size: rem(17);
					}
				}
			}
		}

		@media (max-width: $bp-mobile) {
			li {
				margin-left: 15px;

				.menu-link {
					font-size: rem(11);
				}
			}
		}
	}

	.description {
		color: rgba(0, 0, 0, 0.6);
		font-size: rem(13);
		margin-bottom: 50px;

		a {
			color: rgba(0, 0, 0, 0.6);
		}

		@media (max-width: $bp-tablet) {
			display: none;
		}
	}

	.contact-link {
		color: rgba(0, 0, 0, 0.7);
		font-size: rem(14);

		@media (max-width: $bp-tablet) {
			display: none;
		}
	}
}
