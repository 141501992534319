@import "../../styles/_mixin.scss";

#home {
	display: flex;
	align-items: flex-start;
	padding-left: 100px;
	flex-direction: column;
	justify-content: center;

	@media (max-width: $bp-mobile) {
		padding-left: 50px;
	}

	.name {
		font-size: rem(50);
		margin-bottom: 8px;

		@media (max-width: $bp-mobile) {
			font-size: rem(38);
		}
	}

	.typed-text {
		padding-left: 5px;
		font-size: rem(20);
		display: flex;
		align-items: center;

		.cursor {
			margin-left: 1px;

			&.animate {
				animation: blink 0.7s infinite;
			}
		}
	}
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
